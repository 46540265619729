<template>
  <div>
  
  <HomeView v-if="location.indexOf('krhero') != -1"/>
  <taohaowangHome v-else-if="location.indexOf('hhzyou') != -1"/>




  <iosMhb v-else-if="location.indexOf('shouyoubaoapp') != -1"/>
  <!-- <HomeView v-else-if="location.indexOf('shouyoubaoapp') != -1"/> -->


  <taohaohao v-else/>

  <!-- <iosMhb /> -->

  
  </div>
  
</template>

<script>
import { ref } from "vue";
import HomeView from "./HomeView.vue"
import thhHome from "./thhHome.vue"
import thwHome from "./thwHome.vue"
import mhbHome from "./mhbHome.vue"
import newThwHome from "./newThwHome.vue"
import taohaohao from "./taohaohao.vue"
import thbHome from "./thbHome.vue"
import taohaowangHome from "./taohaowangHome.vue"
import maihaowangHome from "./maihaowangHome.vue"
import iosMhb from "./iosMhb.vue"


export default {
  name: "AboutView",
  components: {
    HomeView,
    thwHome,
    thhHome,
    mhbHome,
    newThwHome,
    taohaohao,
    thbHome,
    taohaowangHome,
    maihaowangHome,
    iosMhb
  },
  setup() {

    const location = window.location.host //获取当前的域名
    console.log('22222')
    console.log(location)

    return {
      location
    };
  },
};


</script>

<style>

</style>

