<template>
    <div class="home">
      <div class="bodder-div">
        <div class="top-div">
          <div class="title-div">淘号网APP</div>
          <div class="content-div">
            <el-image
              style="width: 297px;"
              :src="require('@/assets/taohaowang_first.jpg')"
              fit="contain"
            />
            <div class="title-text-div">
              <div class="title-text">游戏交易上"淘号网"</div>
              <div class="title-text-two">官方保障，安全可靠</div>
              <div class="download-show-div">
                <div class="download-div" @click="anDownMethod">
                  <el-image
                    style="width: 20px; height: 20px"
                    :src="require('@/assets/icon_andrio.png')"
                    fit="cover"
                  />
                  <span class="download-title">安卓下载</span>
                </div>
                <div class="download-div download-left" @click="downMethod">
                  <el-image
                    style="width: 20px; height: 20px"
                    :src="require('@/assets/icon_ios.png')"
                  />
                  <span class="download-title">苹果下载</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="image-show-div">
          <el-image
          style="width: 300px; border: 0.5px solid #E63737;"
            fit="cover"
            :src="require('@/assets/taohaowang_first.jpg')"
          />
          <el-image
          style="width: 300px; border: 0.5px solid #E63737;"
            fit="cover"
            class="left"
            :src="require('@/assets/taohaowang_second.jpg')"
          />
          <el-image
          style="width: 300px; border: 0.5px solid #E63737;"
            fit="cover"
            class="left"
            :src="require('@/assets/taohaowang_third.jpg')"
          />
          <el-image
            style="width: 300px; border: 0.5px solid #E63737;"
            fit="cover"
            class="left"
            :src="require('@/assets/taohaowang_four.jpg')"
          />
        </div>
        <div class="bottom-div">
          <div>公司名称：九江市掌游电子商务有限公司</div>
          <div>公司地址：江西省九江市修水县义宁镇宁红大道万象新城·欢乐金街23#201-203</div>
          <div>ICP备案证书号：赣ICP备2023006803号-1</div>
        </div>
      </div>
    </div>


  </template>
  
  <script>
  import { ElMessage } from "element-plus";
  
  export default {
    name: "taohaowangHome",
    components: {},
  
    setup() {
      function downMethod() {
        ElMessage({
          showClose: true,
          message: "待上架，敬请期待",
          type: "success",
        });
      }

      function anDownMethod(){
        ElMessage({
          showClose: true,
          message: "待上架，敬请期待",
          type: "success",
        });
    //   const openUrl = `https://api.hhaohao.com/app/download`;
    //   var a = document.createElement("a"); // 创建一个<a></a>标签
    //   a.href = openUrl;
    //   a.download = "taohaohao.apk"; // 设置下载文件文件名
    //   a.style.display = "none"; // 隐藏a标签
    //   document.body.appendChild(a); // 将a标签追加到文档对象中
    //   a.click(); // 模拟点击了a标签，会触发a标签的href的读取，浏览器就会自动下载了
    //   a.remove(); // 一次性的，用完就删除a标签
    }

      return { downMethod,anDownMethod };
    },
  };
  </script>
  
  <style scoped>
  .home {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #f8f8f8;
  }
  
  .bodder-div {
    /* margin-top: 20px; */
    width: 1440px;
    background-color: white;
  }
  .top-div {
    height: 528px;
    background-color: #E63737;
    overflow: hidden;
    /* background-color: #ffe417; */
  }
  .title-div {
    margin-left: 120px;
    width: 200px;
    height: 73px;
    background-color: #fef2ee;
    border-radius: 0px 0px 8px 8px;
    opacity: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
    font-family: Alibaba PuHuiTi 2-85 Bold, Alibaba PuHuiTi 20;
    font-weight: normal;
    color: #000000;
    line-height: 28px;
  }
  .content-div {
    display: flex;
    margin-top: 90px;
    padding-left: 200px;
  }
  .title-text-div {
    margin-left: 96px;
    text-align: left;
    padding-top: 12px;
  }
  .title-text {
    font-size: 72px;
    font-family: Alibaba PuHuiTi 2-95 ExtraBold, Alibaba PuHuiTi 20;
    font-weight: normal;
    color: #1a1a1a;
    color: #FFFFFF;
    line-height: 84px;
  }
  .title-text-two {
    font-size: 62px;
    font-family: Alibaba PuHuiTi 2-95 ExtraBold, Alibaba PuHuiTi 20;
    font-weight: normal;
    color: #1a1a1a;
    line-height: 73px;
    margin-top: 10px;
    color: #FFFFFF;

  }
  .image-show-div {
    display: flex;
    padding-top: 87px;
    align-items: center;
    justify-content: center;
    padding-bottom: 60px;
  }
  .left {
    margin-left: 69px;
  }
  .bottom-div {
    background-color: #f8f8f8;
    height: 130px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-family: PingFang SC-常规体, PingFang SC;
    font-weight: normal;
    color: #1a1a1a;
    line-height: 28px;
  }
  .download-div {
    display: flex;
    background-color: #fef2ee;
    align-items: center;
    width: 120px;
    height: 40px;
    margin-top: 10px;
    justify-content: center;
    border-radius: 4px 4px 4px 4px;
    cursor: pointer;
  }
  .download-title {
    margin-left: 4px;
    font-size: 14px;
    font-family: PingFang SC-常规体, PingFang SC;
    font-weight: normal;
    color: #1a1a1a;
    line-height: 28px;
  }
  .download-left {
    margin-left: 20px;
  }
  .download-show-div {
    display: flex;
    padding-left: 6px;
    margin-top: 20px;
  }
  </style>
  